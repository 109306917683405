<template>
	<div>
		<Order simple-text="order-done-voucher" />
	</div>
</template>

<script>
	import Order from "../Order";
  import gAds from "../../../google";

	export default {
		name: "OrderDone",
		metaInfo: {
			title: "Děkujeme za objednávku",
			meta: [{
				name: "robots",
				content: "noindex"
			}]
		},
		components: {Order},
    mounted() {
			if (!this.$storage.order) {
        this.$router.push({
          name: "Home"
        });
        return;
      }

      gAds.conversion(this.$storage.order.total);
    },
  }
</script>